<template>
  <!--<div v-if="proinfo.data.status==6 ">
    <h2 style="color:red">提示：该产品为问题产品，请拒绝购买使用，否则后果自负！</h2>
  </div>
  <div v-else-if=" proinfo.data.queryTime>=20 ">
    <h2 style="color:red">提示，该产品扫码频繁，请谨慎购买！</h2>
  </div>-->
  <div v-if=" proinfo.data.queryTime>=20 ">
    <h2 style="color:red"><van-icon name="fail" />提示，该产品扫码频繁，请谨慎购买！</h2>
  </div>
  <div v-else>
  <van-cell title="追溯码为："  :value=proinfo.data.proCode />
  <van-card
      :title="proinfo.data.proName"
      :price="proinfo.data.proPrice"
      :desc="proinfo.data.productId"
      :thumb="proinfo.data.proPic"
    >
  </van-card>
  <van-cell-group>
    <van-cell title="商品归属"  />
    <van-cell title="归属人:" :value="proinfo.data.userName"  />
    <van-cell title="手机:" :value="proinfo.data.userPhoneNum"  />
  </van-cell-group>
  </div>
</template>

<script>
import { ref,reactive,onMounted,getCurrentInstance,} from 'vue';
import { useRouter } from 'vue-router'
const BASE_URL = process.env.VUE_APP_API_PORT_BASE_URL
export default {
  setup() {
    let code_site=ref()
    let img_url=ref('operration.png')
    let proinfo= reactive({
      data:{}
    });
    let { proxy } = getCurrentInstance();
    
    const router = useRouter()

    onMounted(() => {
      get_income_code()
    });
    /*
    actvate(()=>{
      get_income_code()
    })*/
    const get_income_code=()=>{
      let code_site_code=window.location.href.split("/")[5].slice(0,12)
      console.log(code_site_code);
      if(parseFloat(code_site_code).toString() == "NaN"){
      //code_site.value='https://f2s.pub/'+code_site_code
      //http://w9f.cc/100015546012
      // https://f2s.pub/100246342228
        window.location.href="http://www.aabbccdd.com"
      }else{
        code_site.value='http://w9f.cc/'+code_site_code
        getproinfo()
      }
    };

    const getproinfo=()=>{
      let params = {data: {}}
      params={
        'codeSite':code_site.value
      }
      console.log(params)
      //code/queryCodeMessage，封货后不能使用
      //code/getCodeInfo,用户关闭不能使用
      proxy.$axios.post(BASE_URL+'/code/getCodeInfo',params)
      .then(res=>{
        if(res.data.code==200){
          if(res.data.data.status==6){
            window.location.href="http://www.aabbccdd.com"
          }else{
            proinfo.data={
              'proCode':res.data.data.proCode,
              'proName':res.data.data.proName,
              'proPic':BASE_URL+res.data.data.proPic,
              'proPrice':res.data.data.proPrice,
              'productId':'货号：'+res.data.data.productId,
              'userName':res.data.data.userName,
              'userPhoneNum':res.data.data.userPhoneNum,
              'status':res.data.data.status,
              'queryTime':res.data.data.queryTime
            }
          //(res.data.data[i])


          
          /* proinfo={
            'proCode':res.data.data.proCode,
            'proName':res.data.data.proName,
            'proPic':BASE_URL+res.data.data.proPic,
            'proPrice':res.data.data.proPrice,
            'productId':'货号：'+res.data.data.productId,
            'userName':res.data.data.userName,
            'userPhoneNum':res.data.data.userPhoneNum,
          }*/
          }
        }else{
          window.location.href="http://www.aabbccdd.com"
        }
      })
    };
    return {
      code_site,
      proinfo,
      img_url,
      get_income_code,
      getproinfo,
    };
  },
};


</script>
<template>
  <van-grid :column-num="1"  gutter="12" icon-size="50" direction="horizontal" style="color:#1989fa" >
    <van-grid-item icon="qr" text="扫码录入" @click="onScan"/>
  </van-grid>
</template>
 
<script>
import wx from 'weixin-js-sdk'
import sha1 from "sha1";
import { uuid } from 'vue-uuid';

let k=1
export default {
    name: 'scanCodePage',
    data() {
      return {
        
      }
    },

    mounted(){
     // this.onScan()
    },

    methods: {
       
      onScan(){
        const _this = this
        
        this.$axios.get("weixinJsApiTicket.txt")
        .then(response => {
          console.log(response.data.jsApiTicket)
          //var obj = JSON.parse(response.data);
          
          let jsapi_ticket=response.data.jsApiTicket
          let url=location.href.split("#")[0]
          let timestamp = ''+(new Date().getTime());
          let nonceStr = uuid.v1()
          let sinstr='jsapi_ticket='+jsapi_ticket+'&noncestr='+nonceStr+'&timestamp='+timestamp+'&url='+url
          let signature=sha1(sinstr)
          console.log(sinstr)
          wx.config({
            debug: false,
            appId: 'wxcaf67703b1fab571',
            timestamp: timestamp,
            nonceStr:nonceStr,
            //nonceStr: res.data.jsSdkUiPackage.nonceStr,
            signature: signature,
            jsApiList: ["scanQRCode"]
          });
        });
        wx.error(function (res) {
          Dialog.alert({
            title: "提示",
            message: res.errMsg
          }).then(() => {});
        });
        wx.ready(function () {
            wx.checkJsApi({
              jsApiList: ['scanQRCode'],
              success: function (res) {
              }
            });
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: function (res) {
                      var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                      _this.$emit('returndata',result)
                     /* _this.$router.push({   //跳转到什么页面
                        name: "exwarehouse"
                      });*/
                }
            });
        });
    }
    }
  }
</script>
 
<style lang="less">
.scan-index-bar{
  background-image: linear-gradient( -45deg, #42a5ff ,#59cfff);
}
.van-nav-bar__title{
  color: #fff !important;
}
.scan-video{
  height: 80vh;
}
.scan-tip{
  width: 100vw;
  text-align: center;
  margin-bottom: 10vh;
  color: white;
  font-size: 5vw;
}
.page-scan{
  overflow-y: hidden;
  background-color: #363636;
}
</style>
<template>
  <div>
  <van-nav-bar
  title="重置密码"
  left-arrow
  @click-left="onGoBack"
  />
    <van-form @submit="resetpassword" class='register'>
      <van-field 
        v-model="phonenum" 
        label="手机号：" 
        name="newpd"
        size=large
        placeholder="输入手机号"
        :rules="[{ required: true, message: '请输入密码' }]"
      />
      <van-field
        v-model="newpd"
        type="password"
        name="newpd"
        size=large
        label="新密码："
        placeholder="输入6位以上密码"
        :rules="[{ validator:validatorpassword,required: true, message: '请输入密码' }]"
        />
      <van-field
        v-model="renewpd"
        type="password"
        name="renewpd"
        size=large
        label="确认密码："
        placeholder="输入6位以上密码"
        :rules="[{ validator:reconfigpassword,required: true, message: '请再输入密码一次！' }]"
      />
      <van-field
        v-model="verifynum"
        name="verifynum"
        label="手机验证码："
        size=large
        placeholder="输入验证码"
        :rules="[{ required: true, message: '请输入手机验证码' }]"
      >
       <template #button>
          <van-button size="small" type="primary" round plain @click.prevent="sendverifycode" :disabled="isSmsSend" >{{ sendBtnText }}</van-button>
       </template>
       </van-field>
       <span style="margin:10px;color:red">注意：密码不能设置为123456，否则不能登录系统。</span>
    <van-button type="primary" round block style="width:50%;margin:50px auto 0 auto" native-type="submit" >保存修改</van-button>
    </van-form>
  </div>
</template>

<script>
import { ref,getCurrentInstance,onMounted} from 'vue';
import { useUserStore } from '@/store/user.ts'
import {login} from '@/service/user'
import { useRouter } from 'vue-router'
import { Toast } from 'vant';

const base_url=process.env.VUE_APP_API_PORT_BASE_URL
export default {
  components: {
    [Toast.name]: Toast,
  },

  setup() {

    const  newpd = ref('');
    const  renewpd = ref('');
    const  phonenum = ref('');
    const  verifynum = ref('')
    
    const isSmsSend=ref(false)
    
    
    let { proxy } = getCurrentInstance();

    let sendBtnText=ref('点击发送验证码')
    let timer=ref()
    let counter=ref(180)

    const router = useRouter()
    const userStore = useUserStore()
    
    const validatorpassword=()=>{
      if(newpd.value.length < 6 ){
        return "请输入6位以上密码！"
      }
      if(newpd.value=='123456'){
        return "密码不能为123456，请修改！"
      }
    };
    
    const reconfigpassword=()=>{
      if(newpd.value !== renewpd.value){
        return "两次密码不一致，重新输入！"
      }
    };
    
    //检查手机是否注册
    const getstatus_phone=()=>{

      proxy.$axios.post(base_url+'/user/checkUserAccount',
      {
        userLoginId:phonenum.value
      }).then(res=>{
        if (res.data.code==0){
           toast('手机号未注册，请查实！');
           return
        }
      }).catch(err=>{
        console.log(err)
      })
    };
    
    /**
       * 重置倒计时
       */
      const reset= ()=> {
        isSmsSend.value = false
        sendBtnText.value = '点击发送验证码'
        if (timer.value) {
          clearInterval(timer.value)
          counter.value = 180
          timer.value = null
        }
      };
      /**
       * 倒计时
       */
      const countDown= ()=> {
        isSmsSend.value = true
        timer.value = setInterval(() => {
          sendBtnText.value = `(${counter.value}秒)后重新发送`
          counter.value--
          if (counter.value < 0) {
            reset()
          }
        }, 1000)
      };
    
    //发送短信验证码
    const sendverifycode=()=>{
      // 判断手机号是否已经输入
      console.log(phonenum.value)
      if (!phonenum.value) {
        Toast('请输入手机号');
        return false
      }
      // 判断手机号是否符合要求
      if (phonenum.value.length !== 11) {
        Toast('请输入11位手机号');
        return false
      }

      let params = {data: {}}
      params={userPhoneNum:phonenum.value}
      proxy.$axios.post(base_url+'/authCode/getAuthCode',params)
      .then(res=>{
        console.log(res)
        //Toast(res.msg"短信验证码已发送！")
        Toast(res.data.msg)
      })
      countDown()
    };

    //修改密码
    const resetpassword=()=>{
      let params = {data: {}}
      params={
        phoneNum:phonenum.value,
        authCode:verifynum.value,
        newPwd:newpd.value
      }
      proxy.$axios.post(base_url+'/user/changeUserPwdByAuthCode',params)
      .then(res=>{
        if(res.data.code==200){
          Toast('密码修改成功！');
          router.push('/login');
          /*let name=phonenum.value;
          let password=newpd.value;
          login(name, password).then((result)=>{
            if(result.code >= 0){
              let data={
                id:result.data.id,
                name:result.data.name,
                phone:result.data.phone,
                level:result.data.level,
                token:result.data.token
              };
              userStore.setUserInfo(data)
              router.push('/home');
            }
          })*/
        }else{
          Toast(res.data.msg);
          this.isdisable=false
          this.isloading=false
        }
      })
    };
    
    onMounted(()=>{
      
    });

    return {
      phonenum,
      newpd,
      renewpd,
      verifynum,
      isSmsSend,
      sendBtnText,
      timer,
      counter,
      getstatus_phone,
      sendverifycode,
      resetpassword,
      validatorpassword,
      reconfigpassword,
      reset,
      countDown,
      
    };
  }

  
};
</script>

<style lang="less">

.edit-icon {
    font-size: 16px;
    line-height: inherit;
  }

</style>

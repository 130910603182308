import { useUserStore } from '@/store/user.ts'
import { router } from '@/router/router';
import axios from 'axios'
import { Toast } from 'vant';

export function getUserInfo() {
  return axios.get('/user/info');
}

export function EditUserInfo(params) {
  return axios.put('/user/info', params);
}

//用户登录
const base_url=process.env.VUE_APP_API_PORT_BASE_URL

export  async function login(phone,password) {
  let result = {data: {}}
  let success = false
  await axios.post(base_url+"/user/login",
    {
      userLoginId:phone,
      userLoginPw:password
      //site_vip:site_vip
    }).then(res=>{
      if(res.data.code==200){
        success = true
        console.log(res)
      }else {
        Toast(res.data.msg)
        success = false
        return
      }
      if (success) {
        result.code = 0
        result.message = '欢迎回来'
        result.data.id = res.data.data.user.id
        result.data.name = res.data.data.user.userName
        result.data.phone = res.data.data.user.userPhoneNum
        result.data.level = res.data.data.user.userPrivilege
        result.data.expiretime = '2099-12-31 00:00:00'
        //result.data.level = 3
        result.data.token = res.data.data.token
        //result.data.token = 'Authorization:' + Math.random()
        //result.data.expireAt = new Date(new Date().getTime() + 24*60 * 60 * 1000)
      } else {
        result.code = -1
        result.message = '账户名或密码错误!'
      }
    })
    return result
  };

/*
export  async function login(phone,password,site_vip) {
  let result = {data: {}}
  let success = false
  await axios.post(base_url+"/user/login",
    {
      userLoginId:phone,
      userLoginPw:password,
      site_vip:site_vip
    }).then(res=>{
      if(res.data.code==200){
        success = true
        console.log(res)
      }else {
        Toast(res.data.msg)
        success = false
        return
      }
      if (success) {
        result.code = 0
        result.message = '欢迎回来'
        result.data.id = res.data.data.user.id
        result.data.name = res.data.data.user.userName
        result.data.phone = res.data.data.user.userPhoneNum
        result.data.level = res.data.data.user.userPrivilege
        result.data.expiretime = res.data.data.expirationTime
        //result.data.level = 3
        result.data.token = res.data.data.token
        //result.data.token = 'Authorization:' + Math.random()
        //result.data.expireAt = new Date(new Date().getTime() + 24*60 * 60 * 1000)
      } else {
        result.code = -1
        result.message = '账户名或密码错误!'
      }
    })
    return result
  };
*/
export async function login2(name,password) {
  
  let result = {data: {}}
  let success = false
  
  if (name === 'admin' && password === '123456') {
    success = true
    result.data.permissions = [{id: 'queryForm', operation: ['add', 'edit']}]
    result.data.roles = [{id: 'oper', operation: ['add', 'edit', 'delete']}]
  }else if(name === 'admin' && password === 'admin123'){
    success = true
    result.data.permissions = [{id: 'queryForm', operation: ['add', 'edit']}]
    result.data.roles = [{id: 'admin', operation: ['add', 'edit', 'delete']}]
  } else {
    success = false
  }
  if (success) {
    result.code = 0
    //result.message = Mock.mock('@TIMEFIX').CN + '，欢迎回来'
    result.message = '欢迎回来'
    result.data.user = name
    result.data.token = 'Authorization:' + Math.random()
    result.data.expireAt = new Date(new Date().getTime() + 24*60 * 60 * 1000)
  } else {
    result.code = -1
    result.message = '账户名或密码错误!'
  }
  console.log(result)
  return result
}

const userStore = useUserStore()
export function loginout() {
  userStore.updateName('','','','','')
  router.push('/login');
}

export function register(params) {
  return axios.post('/user/register', params);
}

export function logout2() {
  localStorage.removeItem(process.env.VUE_APP_ROUTES_KEY)
  localStorage.removeItem(process.env.VUE_APP_PERMISSIONS_KEY)
  localStorage.removeItem(process.env.VUE_APP_ROLES_KEY)
  removeAuthorization()
}
